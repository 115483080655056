/*
 * IMPORTANT: Locales for Moment has to be imported in the legacy and standalone
 * entry points. For the peer build it's users responsibility to do so.
 */

// English
const en = {
  current: 'current',
  time: 'time',
  deleteSelected: 'Delete selected',
};
const en_EN = en;
const en_US = en;

// Italiano
const it = {
  current: 'attuale',
  time: 'tempo',
  deleteSelected: 'Cancella la selezione',
};
const it_IT = it;
const it_CH = it;

// Dutch
const nl = {
  current: 'huidige',
  time: 'tijd',
  deleteSelected: 'Selectie verwijderen'
};
const nl_NL = nl;
const nl_BE = nl;

// German
const de = {
  current: 'Aktuelle',
  time: 'Zeit',
  deleteSelected: 'L\u00f6sche Auswahl',
};
const de_DE = de;

// French
const fr = {
  current: 'actuel',
  time: 'heure',
  deleteSelected: 'Effacer la selection',
};
const fr_FR = fr;
const fr_CA = fr;
const fr_BE = fr;

// Espanol
const es = {
  current: 'corriente',
  time: 'hora',
  deleteSelected: 'Eliminar selecci\u00f3n',
};
const es_ES = es;

// Ukrainian
const uk = {
  current: 'поточний',
  time: 'час',
  deleteSelected: 'Видалити обране',
};
const uk_UA = uk;

// Russian
const ru = {
  current: 'текущее',
  time: 'время',
  deleteSelected: 'Удалить выбранное',
};
const ru_RU = ru;

// Polish
const pl = {
  current: 'aktualny',
  time: 'czas',
  deleteSelected: 'Usuń wybrane',
};
const pl_PL = pl;

// Portuguese
const pt = {
  current: 'atual',
  time: 'data',
  deleteSelected: 'Apagar selecionado',
};
const pt_BR = pt;
const pt_PT = pt;

// Japanese
const ja = {
  current: '現在',
  time: '時刻',
  deleteSelected: '選択されたものを削除',
};
const ja_JP = ja;

// Swedish
const sv = {
  current: 'nuvarande',
  time: 'tid',
  deleteSelected: 'Radera valda',
};
const sv_SE = sv;

// Norwegian
const nb = {
  current: 'nåværende',
  time: 'tid',
  deleteSelected: 'Slett valgte',
};
const nb_NO = nb;
const nn = nb;
const nn_NO = nb;

// Lithuanian
const lt = {
  current: 'einamas',
  time: 'laikas',
  deleteSelected: 'Pašalinti pasirinktą',
};
const lt_LT = lt;


const locales = {
  en,
  en_EN,
  en_US,
  it,
  it_IT,
  it_CH,
  nl,
  nl_NL,
  nl_BE,
  de,
  de_DE,
  fr,
  fr_FR,
  fr_CA,
  fr_BE,
  es,
  es_ES,
  uk,
  uk_UA,
  ru,
  ru_RU,
  pl,
  pl_PL,
  pt,
  pt_BR,
  pt_PT,
  ja,
  ja_JP,
  lt,
  lt_LT,
  sv,
  sv_SE,
  nb,
  nn,
  nb_NO,
  nn_NO
}

export default locales
